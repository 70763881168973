import { Controller } from '@hotwired/stimulus';

/**
 * Used for input text fields to overwrite the given value.
 *
 * Values:
 *      validRegex for all accepted input characters
 *      skipRegex to be skipped (also on delete and backspace)
 *      placeholderCharacter to be filled in after deletion again
 *      textTransformation if input should be transformed to uppercase or lowercase
 *
 * For example: Usage for the SPS search.
 * The given input value is like '##-########-######'. The user can overwrite the placeholder characters ('#').
 * The minus characters ('-') will be skipped.
 * As the input should be case-insensitive, the input will be transformed to uppercase values.
 *
 * Usage:
 *      <input type="text" data-controller="input-overwrite-mode"
 *      data-input-overwrite-mode-valid-regex-value="^[A-Za-z0-9#]+$"
 *      data-input-overwrite-mode-skip-regex-value="^\-$"
 *      data-input-overwrite-mode-placeholder-character-value="#"
 *      data-input-overwrite-text-transformation-value="uppercase">
 */
export default class extends Controller {
  static values = {
    validRegex: String,
    skipRegex: String,
    placeholderCharacter: String,
    textTransformation: String
  };
  private validRegexValue!: string;
  private skipRegexValue!: string;
  private placeholderCharacterValue!: string;
  private textTransformationValue!: string;

  connect() {
    const input = <HTMLInputElement>this.element;
    input.addEventListener('keypress', (e) => {
      if (e.target instanceof HTMLInputElement) {
        let key = e.key;
        if (this.textTransformationValue) {
          switch (this.textTransformationValue) {
            case 'uppercase':
              key = key.toUpperCase();
              break;
            case 'lowercase':
              key = key.toLowerCase();
              break;
          }
        }
        if (key != 'Enter' && key != 'ENTER') {
          e.preventDefault();
        }
        const currentPosition = <number>e.target.selectionStart;
        const nextCharacter = input.value.charAt(currentPosition);

        try {
          const regex = new RegExp(this.validRegexValue, 's');
          const skipRegex = new RegExp(this.skipRegexValue, 's');
          const isValid = regex.test(key);

          if (isValid) {
            if (skipRegex.test(nextCharacter)) {
              input.value = input.value.slice(0, currentPosition + 1) + key + input.value.slice(currentPosition + 2);
              e.target.selectionEnd = currentPosition + 2;
            } else if (regex.test(nextCharacter)) {
              input.value = input.value.slice(0, currentPosition) + key + input.value.slice(currentPosition + 1);
              e.target.selectionEnd = currentPosition + 1;
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    });

    input.addEventListener('keydown', (e) => {
      if (e.target instanceof HTMLInputElement) {
        const key = e.key;
        const currentPosition = <number>e.target.selectionStart;
        const nextCharacter = input.value.charAt(currentPosition);
        try {
          const regex = new RegExp(this.validRegexValue, 's');
          const skipRegex = new RegExp(this.skipRegexValue, 's');
          if (key === 'Delete') {
            e.preventDefault();

            if (skipRegex.test(nextCharacter)) {
              input.value = input.value.slice(0, currentPosition + 1) + this.placeholderCharacterValue + input.value.slice(currentPosition + 2);
              e.target.selectionEnd = currentPosition + 2;
            } else if (regex.test(nextCharacter)) {
              input.value = input.value.slice(0, currentPosition) + this.placeholderCharacterValue + input.value.slice(currentPosition + 1);
              e.target.selectionEnd = currentPosition + 1;
            }
          } else if (key === 'Backspace') {
            e.preventDefault();

            const previousCharacter = input.value.charAt(currentPosition - 1);
            if (skipRegex.test(previousCharacter)) {
              input.value = input.value.slice(0, currentPosition - 2) + this.placeholderCharacterValue + input.value.slice(currentPosition - 1);
              e.target.selectionEnd = currentPosition - 1;
            } else if (regex.test(previousCharacter)) {
              input.value = input.value.slice(0, currentPosition - 1) + this.placeholderCharacterValue + input.value.slice(currentPosition);
              e.target.selectionEnd = currentPosition - 1;
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    });

    input.addEventListener('paste', (e) => {
      e.preventDefault();
      const inputElement = e.target;
      if (inputElement instanceof HTMLInputElement) {
        let clipboardData = e.clipboardData?.getData('text');
        if (clipboardData) {
          if (this.textTransformationValue) {
            switch (this.textTransformationValue) {
              case 'uppercase':
                clipboardData = clipboardData.toUpperCase();
                break;
              case 'lowercase':
                clipboardData = clipboardData.toLowerCase();
                break;
            }
          }

          const inputText = inputElement.value;
          const selectionStart = <number>inputElement.selectionStart;

          let clipboardIndex = 0;
          let updatedText = inputText.substring(0, selectionStart);

          // Run through the input text from the selection position and replace "#" characters with clip data
          for (let i = selectionStart; i < inputText.length; i++) {
            const char = inputText.charAt(i);

            if (clipboardIndex >= clipboardData.length) {
              break; // Cancel when there is no more clip data left
            }

            // Skip "-" characters in input text
            let clipboardChar = clipboardData.charAt(clipboardIndex);
            if (char === '-') {
              if (clipboardChar === '-') {
                clipboardIndex++;
              }
              updatedText += char;
              continue;
            } else if (clipboardChar === '-') {
              clipboardIndex++;
            }

            clipboardChar = clipboardData.charAt(clipboardIndex);
            while (clipboardIndex < clipboardData.length && !/^[A-Za-z0-9#]$/.test(clipboardChar)) {
              clipboardIndex++;
              if (clipboardIndex < clipboardData.length) {
                clipboardChar = clipboardData.charAt(clipboardIndex);
              }
            }

            // Validation of the character according to regex
            if (/^[A-Za-z0-9#]$/.test(clipboardChar)) {
              // If the character is "#", replace it with a character from the clip data
              updatedText += clipboardChar;
              clipboardIndex++;
            }
          }
          // Set the cursor position to the end of the inserted clipboard string
          const newPosition = updatedText.length;

          // Add the changed text after selection
          updatedText += inputText.substring(newPosition);
          // Set the new value in the input field
          inputElement.value = updatedText;

          inputElement.setSelectionRange(newPosition, newPosition);
        }
      }
    });
  }
}
