import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['oneButton', 'sideButton', 'allButton'];

  oneButtonTarget!: HTMLButtonElement;
  sideButtonTarget!: HTMLButtonElement;
  allButtonTarget!: HTMLButtonElement;

  connect() {}

  selectRadioButton(event: Event) {
    const row: HTMLElement = event.currentTarget as HTMLElement;
    const radioButton: HTMLInputElement = row.querySelector('input[type=radio]') as HTMLInputElement;
    radioButton.checked = true;

    this.oneButtonTarget.disabled = false;
    this.sideButtonTarget.disabled = false;
    this.allButtonTarget.disabled = false;
  }
}
