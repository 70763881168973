import { Controller } from '@hotwired/stimulus';

/**
 * Allows to click on a table row and check a (not visible) radio button with connection to the data model.
 *
 * Usage:
 * <tr data-suggestion-year="${record.year?string["####"]}"
 * data-controller="table-row-radio-select"
 * data-table-row-radio-select-target="refresh"
 * data-table-row-radio-select-name-value="name of the attribute in the model"
 * data-table-row-radio-select-value-value="${model.value}"
 * data-table-row-radio-select-checked-value="${(condition)?then('checked', '')}"
 * data-action="click->table-row-radio-select#selectRadioButton">
 */
export default class extends Controller {
  static targets = ['refresh'];

  static values = {
    name: String,
    value: String,
    checked: String
  };
  nameValue!: string;
  valueValue!: string;
  checkedValue!: string;

  selectRadioButton() {
    const radioButton = this.element.querySelector('input[type=radio]') as HTMLInputElement;
    radioButton.checked = true;
    const form = radioButton.closest('form');
    if (form) {
      // TODO rename event?
      form.dispatchEvent(new Event('validateQuantities', { bubbles: true }));
    }
  }
}
