import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['capacityProfileSelection', 'submitBtn', 'resetBtn', 'formInputs', 'regulationProfileSelection', 'apCardHeader'];

  static values = {
    capProfUrl: String,
    regProfUrl: String,
    validateUrl: String,
    resetUrl: String
  };

  capacityProfileSelectionTarget!: HTMLSelectElement;
  regulationProfileSelectionTarget!: HTMLSelectElement;
  submitBtnTarget!: HTMLButtonElement;
  resetBtnTarget!: HTMLButtonElement;
  apCardHeaderTarget!: HTMLInputElement;
  hasApCardHeaderTarget!: boolean;
  formInputsTarget!: HTMLDivElement;

  hasCapProfUrlValue!: boolean;
  capProfUrlValue!: string;

  hasRegProfUrlValue!: boolean;
  regProfUrlValue!: string;

  hasValidateUrlValue!: boolean;
  validateUrlValue!: string;

  hasResetUrlValue!: boolean;
  resetUrlValue!: string;

  regProfileIndex = 0;

  connect() {
    if (!this.hasCapProfUrlValue || !this.hasRegProfUrlValue || !this.hasValidateUrlValue || !this.hasResetUrlValue) {
      console.error('[ambient-profiles] You need to pass an url to fetch the remote content.');
      return;
    }
    this.init();
  }

  selectCapacityProfile() {
    const profileIndex = this.capacityProfileSelectionTarget.value;

    const annualSimulationForm = <HTMLFormElement>document.querySelector('#annual-simulation-form');

    fetch(this.capProfUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: profileIndex
    })
      .then((response) => response.text())
      .then((html) => {
        this.formInputsTarget.innerHTML = html;
        this.initValidation();
        this.validate(this.validateUrlValue, this.formInputsTarget, annualSimulationForm, null);
      });
  }

  selectRegulationProfile() {
    const profileIndex = this.regulationProfileSelectionTarget.value;

    this.regProfileIndex = parseInt(profileIndex);
    const annualSimulationForm = <HTMLFormElement>document.querySelector('#annual-simulation-form');

    fetch(this.regProfUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: profileIndex
    })
      .then((response) => response.text())
      .then((html) => {
        this.formInputsTarget.innerHTML = html;
        this.initValidation();
        this.validate(this.validateUrlValue, this.formInputsTarget, annualSimulationForm, null);
      });
  }

  init() {
    this.toggleApCardHeader();
    this.initValidation();
  }

  initValidation() {
    const validate = this.validate;
    const validateUrl = this.validateUrlValue;
    const formInputs = this.formInputsTarget;
    const annualSimulationForm = <HTMLFormElement>document.querySelector('#annual-simulation-form');
    annualSimulationForm?.addEventListener('focusout', function (event) {
      /* eslint-disable-next-line  no-unsafe-optional-chaining */
      const inputList = [...annualSimulationForm?.querySelectorAll('input:not([type=radio], [type=checkbox])')];
      const eventTarget = <HTMLElement>event.target;
      if (inputList.includes(eventTarget)) {
        const eventInput = <HTMLInputElement>event.target;
        if (eventInput.value !== eventInput.defaultValue) {
          validate(validateUrl, formInputs, annualSimulationForm, eventInput.id);
        }
      }
    });
    annualSimulationForm?.addEventListener('change', function (event) {
      /* eslint-disable-next-line  no-unsafe-optional-chaining */
      const inputList = [...annualSimulationForm?.querySelectorAll('input[type=checkbox]')];
      const eventTarget = <HTMLElement>event.target;
      if (inputList.includes(eventTarget)) {
        const eventInput = <HTMLInputElement>event.target;
        if (eventInput.checked !== eventInput.defaultChecked) {
          validate(validateUrl, formInputs, annualSimulationForm, eventInput.id);
        }
      }
    });
  }

  toggleApCardHeader() {
    if (!this.hasApCardHeaderTarget) return;

    if (this.apCardHeaderTarget.checked) {
      document.querySelector('#ambProfileFormClosedClosedArrow')?.classList.add('d-none');
      document.querySelector('#ambProfileFormOpenedClosedArrow')?.classList.remove('d-none');
      document.querySelectorAll('.amb-profile').forEach((el) => {
        el.classList.remove('d-none');
      });
    } else {
      document.querySelector('#ambProfileFormOpenedClosedArrow')?.classList.remove('d-none');
      document.querySelector('#ambProfileFormClosedClosedArrow')?.classList.add('d-none');
      document.querySelectorAll('.amb-profile').forEach((el) => {
        el.classList.add('d-none');
      });
    }
  }

  submit() {
    const spinner: HTMLDivElement = <HTMLDivElement>document.getElementById('simulationSubmitSpinner');
    spinner.classList.remove('d-none');
    this.submitBtnTarget.disabled = true;
    this.resetBtnTarget.disabled = true;
  }

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  validate(url: string, formInputs: HTMLDivElement, form: HTMLFormElement, _changedFieldId: any) {
    const data = new FormData(form);
    // convert to url params
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const params = new URLSearchParams(data as any);

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      body: params.toString()
    })
      .then((response) => response.text())
      .then((html) => {
        formInputs.innerHTML = html;
      });
  }

  reset() {
    this.submitBtnTarget.disabled = true;
    this.resetBtnTarget.disabled = true;
    const formInputs = this.formInputsTarget;
    const url = this.resetUrlValue;
    fetch(url, {
      method: 'POST'
    })
      .then((response) => response.text())
      .then((html) => {
        formInputs.innerHTML = html;
      });
  }
}
