const baseProductId = document.querySelector('#baseProductId');
if (baseProductId) {
  baseProductId.addEventListener('change', (_event) => {
    const customCFmValue = document.querySelector('#customCFmValue');
    if (customCFmValue && customCFmValue.checked) {
      customCFmValue.checked = false;
    }

    const baseProductInfo = document.querySelector('#baseProductInfo');
    if (baseProductInfo && !baseProductInfo.value) {
      baseProductInfo.style.display = 'none';
    }
  });
}
