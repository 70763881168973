import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  private inputTargets!: HTMLInputElement[];

  setOne(event: Event) {
    event.preventDefault();
    this.inputTargets.forEach((elem) => {
      elem.value = '1';
    });
    if (this.inputTargets.length && this.inputTargets[0].form) {
      this.inputTargets[0].form.dispatchEvent(new Event('live', { bubbles: true }));
    }
  }

  setZero(event: Event) {
    event.preventDefault();
    this.inputTargets.forEach((elem) => {
      elem.value = '0';
    });
    if (this.inputTargets.length && this.inputTargets[0].form) {
      this.inputTargets[0].form.dispatchEvent(new Event('live', { bubbles: true }));
    }
  }
}
