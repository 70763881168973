import morphdom from 'morphdom';
import { setupAwesomeplete } from './validator-product-search';

const onReady = (callback) => {
  if (document.readyState !== 'loading') callback();
  else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
  else
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') callback();
    });
};

onReady(() => {
  document.querySelectorAll('[data-validate]').forEach((form) => {
    const formH = form;
    const validateUrl = form.dataset.validate;
    const targetId = form.dataset.validateTargetId;
    setupAwesomeplete();
    const sendUpdateFormRequest = () => {
      const postBody = new URLSearchParams(new FormData(formH)).toString().replace('&_method=patch', '');

      fetch(validateUrl, {
        body: postBody,
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
           'x-content-loader-request': 'true'
        }
      })
        .then((response) => {
          if (response.redirected) {
            window.history.replaceState({},"", response.url);
            return {redirected: true, text: response.text()};
          }
          return {redirected: false, text: response.text()};
        })
        .then((template) => {
          if (template.redirected) {
            template.text.then((t) => document.body.innerHTML = t);
          } else {
            template.text.then((t) => {
              const el1 = document.getElementById(targetId);
              const el2 = document.createElement('div');
              el2.id = targetId;
              el2.innerHTML = t;

              morphdom(el1, el2, {
                onBeforeElChildrenUpdated(el1) {
                  // this has strange effects on select input, let's disable it for now
                  // const activeElement = document.activeElement;
                  // if (activeElement === el1) {
                  //   return false;
                  // }
                  let other = document.querySelector('[data-morphdom-exclude]');
                  return !el1.isEqualNode(other);
                }
              });
              setupAwesomeplete();
            })
          }
        });
    };

    formH.addEventListener('focusout', (event) =>  {
      const element = event.target;
      const list = [...formH.querySelectorAll('input:not([type=radio], [type=checkbox], .jdropdown-header)')];
      if (list.includes(element)) {
        sendUpdateFormRequest();
      }
    });

    formH.addEventListener('change', (event) => {
      const element = event.target;
      const list = [...formH.querySelectorAll('input[type=checkbox], input[type=radio], select')];
      if (list.includes(element)) {
        sendUpdateFormRequest();
      }
    });

    formH.addEventListener('validateQuantities', () => {
      sendUpdateFormRequest();
    });
    window.addEventListener('jsuites-select-change', () => {
      sendUpdateFormRequest();
    });

    document.querySelectorAll('input').forEach((input) => {
      input.addEventListener('input', () => {
        const sibling = input.nextElementSibling;
        if (sibling && sibling.classList.contains('invalid-tooltip')) {
          sibling.remove();
        }
      });
    });
  });
});
