function Animation() {
  const Component = {
    loading: {}
  };

  Component.slideBottom = function (element, direction, done) {
    if (direction === true) {
      element.classList.add('slide-bottom-in');
      setTimeout(function () {
        element.classList.remove('slide-bottom-in');
        if (typeof done == 'function') {
          done();
        }
      }, 400);
    } else {
      element.classList.add('slide-bottom-out');
      setTimeout(function () {
        element.classList.remove('slide-bottom-out');
        if (typeof done == 'function') {
          done();
        }
      }, 100);
    }
  };

  return Component;
}

export default Animation();
