import { Controller } from '@hotwired/stimulus';

import { addToast, hideToast } from './toast-controller';

/**
 * Allows to download a file with a warning that the download may take longer.
 */
export default class extends Controller {
  static values = {
    url: String,
    message: String
  };
  urlValue!: string;
  messageValue!: string;

  connect() {
    this.element.addEventListener('click', (event) => {
      event.preventDefault();

      downloadFile({ toastMessage: this.messageValue, url: this.urlValue })
        .catch((error) => {
          addToast('An error occurred while downloading the file.', { autohide: false });
          console.error('Error downloading file:', error);
        })
        .finally(() => hideToast());
    });
  }
}

export async function downloadFile({
  toastMessage,
  url,
  method = 'GET',
  body,
  headers
}: {
  toastMessage: string;
  url: string;
  method?: string;
  body?: string;
  headers?: HeadersInit;
}) {
  addToast(toastMessage, { autohide: false });
  const res = await fetch(url, {
    method: method,
    body: body,
    headers: headers
  });
  const contentDis = res.headers.get('Content-Disposition');

  if (contentDis) {
    const filename = contentDis.split('filename=')[1].split(';')[0];
    const blob = await res.blob();
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    hideToast();
  }
}
