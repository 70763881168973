import { Controller } from '@hotwired/stimulus';

/**
 * Highlights the current column in a table.
 * This is not easily solvable with CSS at this moment.
 *
 * Usage:
 * <table data-controller="table-column-highlight">
 */
export default class extends Controller {
  connect() {
    const table = this.element;
    const allTableTds = table.querySelectorAll('td');

    allTableTds.forEach(function (td) {
      td.addEventListener('mouseover', function () {
        const tdColumnElements = table.querySelectorAll('td:nth-child(' + (indexOfNode(this) + 1) + ')');
        tdColumnElements.forEach(function (tdColumn) {
          tdColumn.classList.add('highlight');
        });
      });

      td.addEventListener('mouseout', function () {
        const tdColumnElements = table.querySelectorAll('td:nth-child(' + (indexOfNode(this) + 1) + ')');
        tdColumnElements.forEach(function (tdColumn) {
          tdColumn.classList.remove('highlight');
        });
      });
    });
  }
}

function indexOfNode(elem: Element): number {
  if (elem.parentNode) {
    const children = elem.parentNode.childNodes;

    for (let i = 0; i < children.length; i++) {
      if (children[i] == elem) {
        return i;
      }
    }
    return -1;
  } else {
    return -1;
  }
}
