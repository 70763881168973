import { Controller } from '@hotwired/stimulus';

/**
 * Sends a post request when clicking on a radio button.
 * In the background a form is created and submitted.
 * The form elements can be defined via data-form-X, where X is the name of the form parameter.
 *
 * Example: data-form-temp-diff="45" will result in a form parameter with name tempDiff and value 45
 *
 * This means you have to define your endpoint accordingly.
 *
 * Usage:
 *  <input type="radio" data-controller="radio-post" data-target="/my-endpoint/what-ever" data-form-my-param="myParamValue">
 */

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', (event) => {
      event.preventDefault();
      this.handleClick(<HTMLLinkElement>this.element);
    });
  }

  private handleClick(element: HTMLLinkElement) {
    const to = element.dataset.target || '';

    const csrf = this.buildHiddenInput('_csrf_token', 'csrf');
    const form = document.createElement('form');

    form.method = 'post';
    form.action = to;
    form.style.display = 'hidden';

    for (const datasetKey in element.dataset) {
      if (datasetKey.startsWith('form')) {
        const input = this.buildHiddenInput(this.firstToLowerCase(datasetKey.replace('form', '')), element.dataset[datasetKey] || '');
        form.appendChild(input);
      }
    }

    form.appendChild(csrf);
    document.body.appendChild(form);
    form.submit();
  }

  private firstToLowerCase(value: string): string {
    return value.charAt(0).toLowerCase() + value.slice(1);
  }

  private buildHiddenInput(name: string, value: string) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    return input;
  }
}
