import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['subGroup', 'description', 'listPrice', 'submitBtn', 'closeBtn', 'form'];

  private subGroupTarget!: HTMLInputElement;
  private descriptionTarget!: HTMLInputElement;
  private listPriceTarget!: HTMLInputElement;
  private submitBtnTarget!: HTMLButtonElement;
  private closeBtnTarget!: HTMLButtonElement;
  private formTarget!: HTMLFormElement;

  connect() {
    this.submitBtnTarget.disabled = !this.canSubmit();
  }

  validateSubGroup() {
    const errorMsg = document.querySelector('.custom-options-sub-group-error');
    this.setInputValidity(this.isPresent(this.subGroupTarget.value), this.subGroupTarget, errorMsg);
    this.submitBtnTarget.disabled = !this.canSubmit();
  }

  validateOptionDescription() {
    const errorMsg = document.querySelector('.custom-options-description-error');
    this.setInputValidity(this.isPresent(this.descriptionTarget.value), this.descriptionTarget, errorMsg);
    this.submitBtnTarget.disabled = !this.canSubmit();
  }

  validateListPrice() {
    const errorMsg = document.querySelector('.custom-options-list-price-error');
    this.setInputValidity(this.isValidPrice(this.listPriceTarget.value), this.listPriceTarget, errorMsg);
    this.submitBtnTarget.disabled = !this.canSubmit();
  }

  canSubmit(): boolean {
    return this.isPresent(this.subGroupTarget.value) && this.isPresent(this.descriptionTarget.value) && this.isValidPrice(this.listPriceTarget.value);
  }

  submit() {
    this.submitBtnTarget.disabled = true;
    this.closeBtnTarget.disabled = true;
    this.formTarget.submit();
  }

  private setInputValidity(isValid: boolean, inputElement: Element | null, errorElement: Element | null) {
    if (!inputElement || !errorElement) {
      return console.error('Missing input or error element!');
    }

    if (isValid) {
      inputElement.classList.remove('is-invalid');
      errorElement.classList.remove('d-block');
    } else {
      inputElement.classList.add('is-invalid');
      errorElement.classList.add('d-block');
    }
  }

  private isPresent(value: string | null): boolean {
    return !!value;
  }

  private isValidPrice(value: string): boolean {
    const numberRegex = /^[-+]?(\d)*-?([,.]){0,1}-?(\d)+([E,e][-+][\d]+)?%?$/;
    return value.match(numberRegex) !== null;
  }
}
