import Awesomplete from 'awesomplete';

var ap;
var searchElem;

function setupSearchBox(elem) {
  searchElem = elem;
  ap = new Awesomplete(elem, {
    minChars: 1,
    maxItems: 1000,
    autoFirst: true,
    list: []
  });

  document.querySelectorAll('.directSearchRanges').forEach((e) => {
    e.addEventListener('change', refreshListener(searchElem));
  });
  document.querySelectorAll('#directSearchConstruction').forEach((e) => {
    e.addEventListener('change', refreshListener(searchElem));
  });

  return ap;
}

function refreshListener(searchField) {
  const loadingElem = document.querySelector('#loadingDiv');
  if (loadingElem) {
    loadingElem.style.display = 'block';
  }

  const baseProductId = document.querySelector('#baseProductId');
  if (baseProductId) {
    baseProductId.disabled = true;
  }
  document.querySelectorAll('.select').forEach((elem) => {
    elem.disabled = true;
  });

  const searchPath = searchField.dataset.productsSearch;

  fetch(searchPath)
    .then((response) => response.json())
    .then((searchItems) => {
      ap._list = searchItems.map((searchItem) => {
        return {
          value: searchItem,
          label: searchItem
        };
      });
      if (loadingElem) {
        loadingElem.style.display = 'none';
      }
      if (baseProductId) {
        baseProductId.disabled = false;
      }
    });
}

function refreshAwesompleteListener(event) {
  event.preventDefault();
  window.location.href = '/selector/parameter/' + event.text.value + '/';
}
function updateAwesomeplete(elem) {
  if (ap !== undefined && ap !== null) {
    document.querySelectorAll('.directSearchRanges').forEach((e) => {
      e.addEventListener('change', refreshListener(elem));
    });
    updateDirectSearchOnChange();
    // ap.evaluate();
  }
}

function updateDirectSearchOnChange() {
  document.querySelectorAll('#constructions0, #productRanges0').forEach(function (elem) {
    elem.addEventListener('change', (_event) => {
      const baseProductInfo = document.querySelector('#baseProductInfo');
      if (baseProductInfo && !elem.value) {
        baseProductInfo.style.display = 'none';
      }
    });
  });
}
function setupAwesomeplete() {
  document.querySelectorAll('[data-products-search]').forEach((elem) => {
    if (ap === undefined || ap === null) {
      setupSearchBox(elem);
    } else {
      updateAwesomeplete(elem);
    }
  });
  if (document.querySelectorAll('[data-products-search]').length === 0) {
    ap = null;
    removeAwesomeplete();
  }
}

function removeAwesomeplete() {
  if (ap !== undefined && ap !== null) {
    ap.destroy();
    ap = null;
    searchElem.removeEventListener('focus', refreshAwesompleteListener);
  }
}

export { setupSearchBox, refreshAwesompleteListener, updateAwesomeplete, setupAwesomeplete, removeAwesomeplete };
