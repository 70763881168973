import { Controller } from '@hotwired/stimulus';
import Popover from 'bootstrap/js/dist/popover';

/**
 * Opens a popup on an input field. The current input value is send
 * to a controller. The result is rendered in the popup.
 *
 * This can be used for password strength testing.
 *
 * Example usage:
 * <input type="text" data-controller="input-popover" data-action="keyup->input-popover#update" class="form-control">
 */
export default class extends Controller {
  static values = {
    url: String
  };

  hasUrlValue!: boolean;
  urlValue!: string;
  popover!: Popover;

  private input!: HTMLInputElement;

  connect() {
    this.input = <HTMLInputElement>this.element;

    this.popover = new Popover(this.input, {
      html: true,
      trigger: 'focus',
      placement: 'top',
      fallbackPlacements: ['bottom', 'right'],
      // animation: false,
      content: () => {
        return 'Please wait...';
      }
    });
    this.getPasswordTemplate();
  }

  update() {
    this.getPasswordTemplate();
  }

  disconnect() {
    this.popover.dispose();
  }

  private getPasswordTemplate() {
    const url = this.urlValue + '?value=' + encodeURIComponent(this.input.value);
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        // @ts-expect-error this working as we use a fields not part of public api
        this.popover._config.content = html;
        this.popover.setContent();
      });
  }
}
