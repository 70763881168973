import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('keypress', this.keypressEvent(<HTMLInputElement>this.element));
    this.element.addEventListener('keydown', this.keydownEvent(<HTMLInputElement>this.element));
  }

  disconnect() {
    this.element.removeEventListener('keypress', this.keypressEvent(<HTMLInputElement>this.element));
    this.element.removeEventListener('keydown', this.keydownEvent(<HTMLInputElement>this.element));
  }

  // Private functions
  private keypressEvent(elem: HTMLInputElement) {
    return function (_event: Event) {
      return elem.value.length <= 2;
    };
  }

  private keydownEvent(elem: HTMLInputElement) {
    return function (event: Event) {
      const kEvent: KeyboardEvent = <KeyboardEvent>event;

      if (kEvent.keyCode == 13) {
        kEvent.preventDefault();
        if (elem.form) {
          elem.form.dispatchEvent(new Event('live', { bubbles: true }));
        }
      }

      const fnKeyPressed = kEvent.altKey || kEvent.ctrlKey || kEvent.metaKey;

      if (/^[a-zA-Z,.;-]$/.test(kEvent.key) && !fnKeyPressed) {
        event.preventDefault();
      }
    };
  }
}
