import { Controller } from '@hotwired/stimulus';

/**
 * Scrolls to the element when the element is diplayes for the first time.
 */
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.scrollIntoView(false);
    }, 200);
  }
}
