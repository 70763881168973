import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  static values = {
    url: String,
    triggerOnVisible: { type: Boolean, default: false },
    refreshInterval: Number
  };
  static targets = ['progressBar'];

  hasUrlValue!: boolean;
  urlValue!: string;
  private progressBarTarget!: HTMLDivElement;

  triggerOnVisibleValue!: boolean;
  fetched: boolean = false;
  refreshIntervalValue!: number;
  refreshTimer!: ReturnType<typeof setInterval>;

  connect() {
    useIntersection(this);
    if (!this.hasUrlValue) {
      console.error('[calculation-progress] You need to pass an url to fetch the remote content.');
      return;
    }
    if (!this.triggerOnVisibleValue) {
      this.startRefreshing();
    }
  }

  disconnect() {
    this.stopRefreshing();
  }

  fetchContent(progressBarElement: HTMLDivElement) {
    fetch(this.urlValue, {
      headers: new Headers({
        'x-content-loader-request': 'true'
      })
    })
      .then((response) => response.json())
      .then((json) => {
        const percentage = (json.count / json.total) * 100;
        progressBarElement.style.width = percentage + '%';
        progressBarElement.innerHTML = Math.ceil(percentage) + '%';
      });
  }

  startRefreshing() {
    const progressBarElement = this.progressBarTarget;
    this.refreshTimer = setInterval(() => {
      this.fetchContent(progressBarElement);
    }, this.refreshIntervalValue);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}
