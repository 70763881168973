import { Controller } from '@hotwired/stimulus';

/**
 * User needs to enable checkbox to enable submit button
 */
export default class extends Controller {
  static targets = ['submit'];

  private submitTarget!: HTMLButtonElement;

  check(event: Event) {
    const checkbox = <HTMLInputElement>event.currentTarget;
    this.submitTarget.disabled = !checkbox.checked;
  }
}
