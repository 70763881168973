import { Controller } from '@hotwired/stimulus';

/**
 * This is a general collections of tools which helps with JavaScript in forms.
 *
 * Tools:
 * * Submit form on select change
 * * Submit form on input focus lost
 * * Clear other input fields on change
 *
 * Usage:
 * <form data-controller="form-tools">
 *     <select data-action="form-tools#submit">...</select>
 *     <input data-action="blur->form-tools#submit" />
 *
 *     <select data-action="form-tools#clear" data-form-tools-clear-param="#prefix,#productRangeId">...</select>
 * </form>
 */
export default class extends Controller {
  submit({ currentTarget: { form } }: { currentTarget: HTMLInputElement }) {
    if (form) {
      form.submit();
    } else {
      console.log('No form present');
    }
  }

  clear({ params: { clear } }: { params: { clear: string } }) {
    document.querySelectorAll<HTMLInputElement>(clear).forEach((inputElement) => {
      inputElement.value = '';
    });
  }
}
