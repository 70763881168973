import { Controller } from '@hotwired/stimulus';

/**
 * Allows a normal table to be searched via input field.
 * The complete row is searched for the term.
 *
 * Usage:
 * <div data-controller="table-search">
 *   <input type="text" data-action="keyup->table-search#search">
 *   <table>
 *     <tr data-table-search-target="row"><td>Content</td></tr>
 *   </table>
 *
 */
export default class extends Controller {
  static targets = ['row'];

  private rowTargets!: HTMLTableRowElement[];

  search(event: Event) {
    const searchTerm = (<HTMLInputElement>event.currentTarget).value.toLowerCase();

    this.rowTargets.forEach((rowTarget) => {
      const rowText = rowTarget.innerText.toLowerCase();
      rowTarget.hidden = rowText.indexOf(searchTerm) === -1;
    });
  }
}
