import { Controller } from '@hotwired/stimulus';
import { Client } from '@stomp/stompjs';

export default class extends Controller {
  static targets = [
    'table',
    'noProducts',
    'searchingProducts',
    'progressBar',
    'error',
    'progress',
    'ambProfilesForm',
    'downloadLink',
    'leadTimeFilterAlert',
    'leadTimeProgress',
    'requestPricesButton'
  ];

  declare readonly hasRequestPricesButtonTarget: boolean;

  private tableTarget!: HTMLTableElement;
  private noProductsTarget!: Element;
  private searchingProductsTarget!: Element;
  private progressBarTarget!: HTMLDivElement;
  private errorTarget!: Element;
  private progressTarget!: HTMLDivElement;
  private ambProfilesFormTarget!: HTMLFormElement;
  private downloadLinkTarget!: HTMLDivElement;
  private leadTimeFilterAlertTarget!: HTMLDivElement;
  private leadTimeProgressTarget!: HTMLDivElement;
  private requestPricesButtonTarget!: Element;

  private done = false;
  private percentage = '0%';

  updateProgressBar() {
    if (!this.done) {
      this.progressBarTarget.style.width = this.percentage;
      this.progressBarTarget.innerHTML = this.percentage;
      requestAnimationFrame(() => this.updateProgressBar());
    }
  }

  async connect() {
    this.fillTable();
    this.updateProgressBar();
  }

  fillTable() {
    const client = new Client({});
    let rowCount = 0;
    let dataRequested = false;

    const tableElementBody = this.tableTarget.querySelector('tbody');

    const socketId = this.tableTarget.getAttribute('data-socket-id');
    const maxResultsValue = this.tableTarget.getAttribute('data-max-results');
    const maxResults = maxResultsValue !== null ? parseInt(maxResultsValue) : 0;

    const mutationObserver = new MutationObserver((mutationList: MutationRecord[], _observer: MutationObserver) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          this.leadTimeProgressTarget.classList.add('d-none');
        }
      }
    });

    client.brokerURL = (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host + '/ws';

    client.onConnect = (_frame) => {
      const listSubscription = client.subscribe(`/product_list/${socketId}/list`, (productString) => {
        const product = JSON.parse(productString.body);
        const tableRow = product.tableRow;

        if (tableElementBody) {
          tableElementBody.insertAdjacentHTML('beforeend', tableRow);
        }

        this.searchingProductsTarget.classList.add('d-none');
        rowCount += 1;
        const percentageCount = (rowCount / maxResults) * 100;
        if (percentageCount > 100) {
          this.percentage = 100 + '%';
        } else {
          this.percentage = Math.ceil(percentageCount) + '%';
        }
      });

      window.addEventListener('beforeunload', (_event) => {
        try {
          this.downloadLinkTarget.classList.add('d-none');
          this.leadTimeFilterAlertTarget.classList.add('d-none');
          this.leadTimeProgressTarget.classList.add('d-none');
          listSubscription.unsubscribe();
          client.deactivate();
          mutationObserver.disconnect();
        } catch (e) {
          // empty on purpose, nothing to handle here
        }
      });

      client.subscribe(`/product_list/${socketId}/remove`, function (productString) {
        const product = JSON.parse(productString.body);
        if (tableElementBody) {
          const tRow = document.getElementById(product.internalNo);
          if (tRow) {
            tRow.remove();
          }
        }
      });

      client.subscribe(`/product_list/${socketId}/finish`, (errorMessage) => {
        if (errorMessage && errorMessage.body) {
          this.errorTarget.innerHTML = errorMessage.body;
          this.errorTarget.classList.remove('d-none');
        }

        if (rowCount === 0) {
          this.noProductsTarget.classList.remove('d-none');
          this.searchingProductsTarget.classList.add('d-none');
        }

        if (rowCount > 0 && rowCount <= 6) {
          mutationObserver.observe(this.leadTimeFilterAlertTarget, {
            attributes: false,
            childList: true,
            subtree: true
          });
          this.leadTimeFilterAlertTarget.classList.remove('d-none');
          this.leadTimeProgressTarget.classList.remove('d-none');
        }

        this.tableTarget.querySelectorAll('th').forEach((it) => {
          it.removeAttribute('data-table-sort-target');
        });

        if (this.hasRequestPricesButtonTarget) {
          this.requestPricesButtonTarget.removeAttribute('disabled');
          this.requestPricesButtonTarget.classList.remove('disabled');
        }
        // Don't automatically unsubscribe any longer, use unsubscribe in beforeunload event to trigger download cache cleanup
        // listSubscription.unsubscribe();
        // client.deactivate();

        // handle download bar when ambient profiling activated
        const downloadBar = <HTMLDivElement>document.querySelector('#download-bar');

        if (downloadBar) {
          const locationSelected = downloadBar.dataset.locationSelected;
          if (locationSelected === 'true') {
            downloadBar.classList.remove('d-none');
            const downloadBtn = document.querySelector('[data-download-btn]');
            const compareBtn = document.querySelector('[data-compare-btn]');
            const productSelectionText = document.querySelector('#product-selection-text');
            if (downloadBtn) {
              downloadBtn.classList.add('d-none');
            }
            if (compareBtn) {
              compareBtn.classList.add('d-none');
            }
            if (productSelectionText) {
              productSelectionText.classList.add('d-none');
            }
          }
        }

        setTimeout(() => {
          this.progressTarget.style.display = 'none';
          this.done = true;
        }, 50);
      });

      if (!dataRequested) {
        // Call stream only once
        fetch(`/selector/product_list/stream?id=${socketId}`);
        dataRequested = true;
      }
    };
    client.activate();
  }

  startAmbProfiling(event: Event) {
    event.preventDefault();
    const checkedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll('[data-download-check]:checked');
    const checkboxesToSubmit: NodeListOf<HTMLInputElement> =
      checkedCheckboxes.length > 0
        ? checkedCheckboxes // selected products in list
        : document.querySelectorAll('[data-download-check]'); // all products in list
    const productIds: string[] = [];
    const quantities: string[] = [];

    if (checkboxesToSubmit.length > 0) {
      checkboxesToSubmit.forEach((checkbox: HTMLInputElement) => {
        const id = checkbox.dataset.downloadCheck;
        const quantity = checkbox.dataset.downloadQuantity + '';
        if (id != null) {
          productIds.push(id);
          quantities.push(quantity);
        }
      });

      this.submitAmbProfilingForm(productIds, quantities);
    }
  }

  submitAmbProfilingForm(ids: string[], quantities: string[]) {
    const inputField = <HTMLInputElement>document.createElement('input');
    inputField.type = 'hidden';
    inputField.name = 'productIds[]';
    inputField.value = ids.toString();
    this.ambProfilesFormTarget.append(inputField);

    const inputField_qty = <HTMLInputElement>document.createElement('input');
    inputField_qty.type = 'hidden';
    inputField_qty.name = 'quantities[]';
    inputField_qty.value = quantities.toString();
    this.ambProfilesFormTarget.append(inputField_qty);

    this.ambProfilesFormTarget.submit();
  }
}
