import { Controller } from '@hotwired/stimulus';

/* */
export default class extends Controller {
  connect() {
    const image = <HTMLImageElement>this.element;
    image.addEventListener('error', () => {
      image.style.display = 'none';
    });
  }
}
