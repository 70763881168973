const onReady = (callback) => {
  if (document.readyState !== 'loading') callback();
  else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
  else
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') callback();
    });
};

onReady(() => {
  let showPrices = document.querySelector('#showPrices');
  if (!showPrices) return;
  showPrices.addEventListener('change', (event) => {
    let showPricesEnabled = event.target.checked;
    let showDiscountToggle = document.querySelector('#showDiscount');
    let showVAT = document.querySelector('#showVAT');
    if (!showPricesEnabled) {
      showDiscountToggle.disabled = true;
      showDiscountToggle.checked = false;
      showVAT.disabled = true;
      showVAT.checked = false;
    } else {
      showDiscountToggle.disabled = false;
      showDiscountToggle.checked = true;
      showVAT.disabled = false;
    }
  });
});
