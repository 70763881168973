import { Controller } from '@hotwired/stimulus';
import Tooltip from 'bootstrap/js/dist/tooltip';

/**
 * This is a wrapper around the bootstrap tooltip component.
 *
 * You can use it with morphdom without issues.
 * All the normal options from bootstrap still apply.
 * See the documentation here: https://getbootstrap.com/docs/5.1/components/tooltips/#options
 *
 * Attention: Doesn't work with disabled elements/buttons.
 *
 * Usage:
 * <span data-controller="tooltip" title="This is tooltip" data-bs-placement="right"></span>
 *
 */
export default class extends Controller {
  tooltip!: Tooltip;

  connect() {
    this.tooltip = new Tooltip(this.element, {
      animation: false,
      trigger: 'hover'
    });
  }

  disconnect() {
    this.tooltip.dispose();
  }
}
