import { Controller } from '@hotwired/stimulus';

/**
 * Allows to disable / enabled target elements when current checkbox is checked.
 *
 * <@form.switch ... attributes="data-controller='checkbox-disable' data-checkbox-disable-target-selector-value='#showDiscount, #showPrices'" />
 *
 */
export default class extends Controller {
  static values = {
    targetSelector: String
  };
  targetSelectorValue!: string;

  connect() {
    this.element.addEventListener('input', this.inputChange(<HTMLInputElement>this.element));
  }

  disconnect() {
    this.element.removeEventListener('input', this.inputChange(<HTMLInputElement>this.element));
  }

  inputChange(elem: HTMLInputElement) {
    return (_event: Event) => {
      const checked = elem.checked;
      const targetElems = document.querySelectorAll<HTMLInputElement>(this.targetSelectorValue);

      targetElems.forEach(function (targetElem) {
        targetElem.disabled = checked;
      });
    };
  }
}
