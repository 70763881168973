import { Controller } from '@hotwired/stimulus';
import morphdom from 'morphdom';
import Modal from 'bootstrap/js/dist/modal';

/**
 * This is a generalized version of a bootstrap modal.
 * How it works:
 * Every layout has a modal container included.
 *
 * Usage:
 * <a data-action="modal#show" href="..."
 *
 * Usage for POST request:
 * <a data-action="modal#show" data-modal-method-param="POST" href="..."
 *
 * Usage if modal has own stimulus controller:
 * Add controller name and module as action parameter.
 * The module is optional and should be given, if controller is found in a subdirectory of 'controllers'.
 *
 * <a data-action "modal#show" data-modal-controller-param="test-controller" data-modal-module-param="rt" href="..."
 */
export default class extends Controller {
  static targets = ['container'];

  private containerTarget!: HTMLDivElement;

  async show(event: Event) {
    event.preventDefault();
    const currentTarget = <HTMLElement>event.currentTarget;
    const method = currentTarget.dataset.modalMethodParam ? currentTarget.dataset.modalMethodParam : 'GET';
    const templateUrl = (<HTMLLinkElement>event.currentTarget).href || (<HTMLButtonElement>event.currentTarget).dataset.href;
    const modalElement = this.containerTarget.firstElementChild;

    if (modalElement && templateUrl) {
      const template = await fetch(templateUrl, { method: method }).then((response) => response.text());
      morphdom(modalElement, template);
      new Modal(modalElement, {}).show();
      modalElement.addEventListener('hidden.bs.modal', () => {
        modalElement.innerHTML = '';
        this.dispatch('hidden', {});
      });
      const controllerParam = currentTarget.dataset.modalControllerParam;
      if (controllerParam) {
        const moduleParam = currentTarget.dataset.modalModuleParam;
        const module = moduleParam ? moduleParam + '/' : '';
        import('./' + module + controllerParam + '-controller').then((controller) => {
          this.application.register(controllerParam, controller.default);
        });
      }
    } else {
      alert('Modal not found!');
    }
  }
}
