import { Controller } from '@hotwired/stimulus';

/**
 * Disables a button after clicking and adds a spinner.
 *
 * Usage:
 * <button data-controller="button-disable">Click me</button>
 */
export default class extends Controller {
  spinner = ` <div class="sk-chase-em">
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                        </div>`;

  connect() {
    const button = <HTMLButtonElement>this.element;
    const spinner = this.spinner;
    const onceParam: boolean = button.dataset.buttonDisableOnceParam ? button.dataset.buttonDisableOnceParam === 'true' : true;
    this.element.addEventListener(
      'click',
      (_event) => {
        button.insertAdjacentHTML('beforeend', spinner);
        setTimeout(() => (button.disabled = true), 1);
      },
      { once: onceParam }
    );
  }
}
