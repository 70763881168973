import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const table = <HTMLTableElement>this.element;
    const tableHead = table.querySelector('thead');
    const tableBody = table.querySelector('tbody');
    const newRows: string[] = [];

    table.querySelectorAll('tr').forEach((trElement) => {
      trElement.querySelectorAll('td, th').forEach((elem, i) => {
        if (newRows[i] === undefined) {
          newRows[i] = '';
        }
        newRows[i] += elem.outerHTML;
      });
      trElement.remove();
    });

    if (tableHead && tableBody) {
      newRows.forEach((rowStr, index) => {
        const rowStrWithTr = '<tr>' + rowStr + '</tr>';
        if (index === 0) {
          tableHead.insertAdjacentHTML('beforeend', rowStrWithTr);
        } else {
          tableBody.insertAdjacentHTML('beforeend', rowStrWithTr);
        }
      });
    }
  }
}
