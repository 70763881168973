import { Controller } from '@hotwired/stimulus';
import Awesomplete from 'awesomplete';

/**
 * This is a simple wrapper around awesomeplete to
 * give an easy option for the most basic search functionality
 *
 * This controller expects that you return a json object with the
 * complete label and the url you want to go to on selection.
 *
 * Usage:
 * <input type="text" class="form-control form-control-sm"
 *   data-controller="search"
 *   data-search-api-path-value="/spare_parts/api/basic_products"
 *   data-search-url-value="url"
 *   data-search-label-value="name"
 *   data-action="focus->search#load:once awesomplete-select->search#gotoUrl">
 *
 */
export default class extends Controller {
  static targets = ['searchInput', 'filterInput'];

  static values = {
    apiPath: String,
    url: String,
    label: String
  };

  ap!: Awesomplete;
  apiPathValue!: string;
  urlValue!: string;
  labelValue!: string;
  searchInputTarget!: HTMLInputElement;
  hasSearchInputTarget!: boolean;
  filterInputTargets!: HTMLInputElement[];

  load() {
    const url = this.urlWithFilterTargets(this.apiPathValue);
    const searchInputElement = this.hasSearchInputTarget ? this.searchInputTarget : this.element;
    this.ap = new Awesomplete(searchInputElement, {
      minChars: 1,
      autoFirst: true,
      list: []
    });

    fetch(url)
      .then((response) => response.json())
      .then((searchItems) => {
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        this.ap.list = searchItems.map((searchItem: any) => {
          return {
            value: searchItem[this.urlValue],
            label: searchItem[this.labelValue]
          };
        });
        this.ap.evaluate();
      });

    (<HTMLInputElement>this.element).focus();
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  gotoUrl(event: any) {
    window.location.href = event.text.value;
    event.preventDefault();
  }

  private urlWithFilterTargets(baseUrl: string) {
    const url = new URL(baseUrl, window.location.origin);
    for (const filterInputElement of this.filterInputTargets) {
      url.searchParams.append(filterInputElement.name, filterInputElement.value);
    }
    return url.toString();
  }
}
