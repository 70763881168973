import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    targetId: String
  };
  targetIdValue!: string;

  connect() {
    const targetElem: HTMLInputElement | null = <HTMLInputElement>document.getElementById(this.targetIdValue);
    if (targetElem.value) {
      (<HTMLInputElement>this.element).value = targetElem.value;
    }

    targetElem?.addEventListener('input', this.inputChange(<HTMLInputElement>this.element));
  }

  disconnect() {
    const targetElem: HTMLInputElement | null = <HTMLInputElement>document.getElementById(this.targetIdValue);
    targetElem?.removeEventListener('input', this.inputChange(<HTMLInputElement>this.element));
  }

  inputChange(elem: HTMLInputElement) {
    return function (event: Event) {
      const inputValue = (<HTMLInputElement>event.target).value;
      elem.value = inputValue;
    };
  }
}
