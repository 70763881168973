import { Controller } from '@hotwired/stimulus';
import { addToast } from '../toast-controller';

export default class extends Controller {
  activate(event: Event) {
    const checkbox = <HTMLInputElement>event.target;
    const productOptionId = checkbox.dataset.productOptionId;

    const data = {
      productOptionId: productOptionId,
      active: checkbox.checked
    };

    fetch('/admin/rt/options/activate', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      body: JSON.stringify(data)
    })
      .then((response) => response.text())
      .then((data) => addToast(data, {}));
  }
}
