import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        selectedOption: String
    };

    selectedOptionValue!: string;

    deselectOption(e: Event){
        // @ts-ignore
        document.getElementById(`option-row-${this.selectedOptionValue.replace(".", "-")}`).click();
    }
}