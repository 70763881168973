import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['firstHeaderRow', 'secondHeaderRow'];

  firstHeaderRowTarget!: HTMLTableRowElement;
  secondHeaderRowTarget!: HTMLTableRowElement;
  hasFirstHeaderRowTarget!: boolean;
  hasSecondHeaderRowTarget!: boolean;

  connect() {
    window.addEventListener('resize', () => this.configureTableHeaders());
    this.configureTableHeaders();
  }

  configureTableHeaders() {
    if (this.hasFirstHeaderRowTarget && this.hasSecondHeaderRowTarget) {
      const firstHeight = this.firstHeaderRowTarget.offsetHeight;
      for (let i = 0; i < this.secondHeaderRowTarget.cells.length; i++) {
        this.secondHeaderRowTarget.cells[i].style.top = firstHeight + 'px';
      }
    }
  }
}
