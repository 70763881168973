import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'fileInput',
    'checkEMail',
    'checkResults',
    'downloadLink',
    'downloadLinkArea',
    'downloadErrorArea',
    'downloadError',
    'downloadArea'
  ];

  spinner = ` <div class="sk-chase-em">
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                        </div>`;

  checkEMailTarget!: HTMLInputElement;
  checkResultsTarget!: HTMLInputElement;
  fileInputTarget!: HTMLInputElement;
  downloadLinkTarget!: HTMLAnchorElement;
  downloadLinkAreaTarget!: HTMLAnchorElement;
  downloadErrorTarget!: HTMLAnchorElement;
  downloadErrorAreaTarget!: HTMLAnchorElement;
  downloadAreaTarget!: HTMLDivElement;

  async submit(event: Event) {
    const button = <HTMLButtonElement>event.currentTarget;

    if (this.fileInputTarget.value && this.fileInputTarget.files) {
      this.fileInputTarget.classList.remove('is-invalid');
      this.fileInputTarget.disabled = true;

      this.downloadAreaTarget.classList.add('d-none');
      this.downloadLinkAreaTarget.classList.add('d-none');
      this.downloadErrorAreaTarget.classList.add('d-none');

      button.insertAdjacentHTML('beforeend', this.spinner);
      setTimeout(() => (button.disabled = true), 1);

      const formData = new FormData();
      formData.append('file', this.fileInputTarget.files[0]);
      formData.append('email', this.checkEMailTarget.checked ? '1' : '0');
      formData.append('results', this.checkResultsTarget.checked ? '1' : '0');

      fetch('/rt/eurovent_listing', {
        method: 'post',
        redirect: 'follow',
        body: formData
      })
        .then((response) => {
          if (response.status == 202) {
            response.text().then((body) => {
              this.downloadErrorTarget.innerText = body;
            });
            this.showErrorMessage();
          } else if (response.status != 201) {
            this.downloadErrorTarget.innerText = 'Unexpected response ' + response.status + ' - ' + response.statusText;
            this.showErrorMessage();
            this.resetForm(button);
          } else {
            const url = response.headers.get('Location')!;
            window.addEventListener('beforeunload', function (_event) {
              fetch(url + '?cancel=true'); // cancel the ongoing background task if page is left prematurely
            });
            fetch(url, {
              method: 'get',
              redirect: 'follow'
            })
              .then((response) => {
                if (!response.ok) {
                  if (response.status != 409) {
                    this.downloadErrorTarget.innerText = 'HTTP error with status code: ' + response.status;
                    fetch(url + '?cancel=true'); // make sure to cancel the ongoing background task if the error was not handled by the asyncDownload directly
                  } else {
                    response.text().then((body) => {
                      this.downloadErrorTarget.innerText = body;
                    });
                  }
                  this.showErrorMessage();
                } else {
                  const contentDis = response.headers.get('Content-Disposition');

                  if (contentDis) {
                    const filename = contentDis.split('filename=')[1].split(';')[0];
                    response.blob().then((blob) => {
                      this.downloadLinkTarget.href = window.URL.createObjectURL(blob);
                      this.downloadLinkTarget.setAttribute('download', filename);
                      this.downloadLinkTarget.text = filename;

                      this.showDownloadResult();
                    });
                  }
                }
                this.resetForm(button);
              })
              .catch((error: Error) => {
                console.log(error); /* <-- made up logging service */
                this.downloadErrorTarget.innerText = 'Network error: ' + error.message;

                this.showErrorMessage();
              });
          }
        })
        .catch((error: Error) => {
          console.log(error); /* <-- made up logging service */
          this.downloadErrorTarget.innerText = 'Network error: ' + error.message;

          this.showErrorMessage();
          this.resetForm(button);
        });
    } else {
      setTimeout(() => {
        button.disabled = false;
        button.firstElementChild?.remove();
        this.fileInputTarget.classList.add('is-invalid');
      }, 2);
    }
  }

  private resetForm(button: HTMLButtonElement) {
    button.disabled = false;
    button.firstElementChild?.remove();
    this.fileInputTarget.value = '';
    this.fileInputTarget.disabled = false;
  }

  private showDownloadResult() {
    this.downloadAreaTarget.classList.remove('d-none');
    this.downloadLinkAreaTarget.classList.remove('d-none');
    this.downloadErrorAreaTarget.classList.add('d-none');
  }

  private showErrorMessage() {
    this.downloadErrorAreaTarget.classList.remove('d-none');
    this.downloadAreaTarget.classList.remove('d-none');
    this.downloadLinkAreaTarget.classList.add('d-none');
  }
}
