import { Controller } from '@hotwired/stimulus';

/**
 * Allows to highlight an element based on the status of a checkbox.
 * Works nicely with checkbox-select (all/none)
 *
 * Usage:
 * <tr data-controller="checkbox-highlight" data-checkbox-highlight-class-value="table-info">
 *     <td><input type="checkbox" data-action="change->checkbox-highlight#toggle"></td>
 * </tr>
 *
 */
export default class extends Controller {
  static values = {
    class: String
  };

  private classValue!: string;

  toggle(event: Event) {
    const checkbox = <HTMLInputElement>event.currentTarget;

    if (checkbox.checked) {
      this.element.classList.add(this.classValue);
    } else {
      this.element.classList.remove(this.classValue);
    }
  }
}
