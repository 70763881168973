import { Controller } from '@hotwired/stimulus';

/**
 * Sets the state of a checkbox (selectAllCheckbox) to the indeterminate state, depending on the selection of other checkboxes (checkbox).
 * As soon as a checkbox is selected (the change event is triggered), the valid status for the "selectAll" checkbox is checked and set.
 *
 * Usage:
 * <div data-controller="checkbox-indeterminate">
 *      <input type="checkbox" data-checkbox-indeterminate-target="selectAllCheckbox"/>
 *      <label>Delete all</label>
 *
 *      <#list kelvionCodes as kelvionCode>
 *          <input type="checkbox" data-checkbox-indeterminate-target="checkbox" name="..." value="..."/>
 *          <label>Delete</label>
 *      </#list>
 * </div>
 */
export default class extends Controller {
  static targets = ['selectAllCheckbox', 'checkbox'];

  private selectAllCheckboxTarget!: HTMLInputElement;
  private checkboxTargets!: HTMLInputElement[];

  connect() {
    this.checkboxTargets.forEach((checkbox) =>
      checkbox.addEventListener('change', () => {
        this.selectAllCheckboxTarget.checked = Array.from(this.checkboxTargets).every((checkbox) => checkbox.checked);
        this.selectAllCheckboxTarget.indeterminate = new Set(this.checkboxTargets.map((checkbox) => checkbox.checked)).size !== 1;
      })
    );
  }
}
