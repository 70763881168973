import { Controller } from '@hotwired/stimulus';
import morphdom from 'morphdom';
import Modal from 'bootstrap/js/dist/modal';

export default class extends Controller {
  private weatherModal!: Modal | null;

  connect() {
    if (this.weatherModal) {
      return;
    }
    const validationModal = document.getElementById('weatherValidationErrorModal') as HTMLElement | null;
    if (validationModal) {
      const hasValidationMessages = validationModal.dataset.validationMessages;
      if (hasValidationMessages) {
        const modalContent = document.getElementById('weatherValidationErrorModal');

        // find the modal container we should use
        const element = document.querySelector("div[data-modal-target='container']") as HTMLElement;
        const modalElement = element!.firstElementChild as HTMLElement;

        if (modalContent && modalElement) {
          // morphing the dom -> putting the rendered template inside the modal container
          morphdom(modalElement!, modalContent!.outerHTML);
          this.weatherModal = new Modal(modalElement!, { backdrop: 'static' });
          this.weatherModal.show();
          modalElement!.addEventListener('hidden.bs.modal', () => (modalElement!.innerHTML = ''));
        } else {
          alert('Modal not found!');
        }
      }
    }
  }

  disconnect() {
    this.weatherModal!.dispose();
    this.weatherModal = null;
    super.disconnect();
  }
}
