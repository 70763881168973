import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['overlay'];

  private overlayTarget!: HTMLDivElement;

  block(_event: Event) {
    this.overlayTarget.classList.remove('hiddenDiv');
    this.dispatch('block');
  }
}
