import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String
  };

  hasUrlValue!: boolean;
  urlValue!: string;

  connect() {
    const form = <HTMLFormElement>this.element;

    form.addEventListener('submit', (event) => {
      event.preventDefault();
      if (this.hasUrlValue) {
        this.morphSubmit(form, this.urlValue);
      }
    });
  }

  morphSubmit(form: HTMLFormElement, url: string) {
    // @ts-expect-error it is fine to create URLSearchParams without size, as the server has a default value
    const postBody = new URLSearchParams(new FormData(form)).toString().replace('&_method=patch', '');
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

    fetch(url, { body: postBody, headers: headers, method: 'post', redirect: 'follow' })
      .then((response) => {
        if (response.redirected) {
          window.location.replace(response.url);
          return;
        }
        return response.text();
      })
      .then(function (template) {
        if (template) {
          form.innerHTML = template;
        }
      });
  }
}
