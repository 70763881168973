import { Controller } from '@hotwired/stimulus';

/**
 * Makes a link work as a submit button.
 *
 * Usage:
 * <form action="...">
 *     <a href data-controller="link-submit">Save</a>
 * </form>
 */
export default class extends Controller {
  connect() {
    this.element.addEventListener('click', (event) => {
      event.preventDefault();
      const form = <HTMLFormElement>this.element.closest('form');

      if (form) {
        form.submit();
      }
    });
  }
}
