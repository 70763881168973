import { Controller } from '@hotwired/stimulus';

/**
 * Allows to click on a table row and check a checkbox with connection to the data model.
 *
 * Usage:
 * <tr
 * data-controller="table-row-checkbox-select"
 * data-table-row-checkbox-select-event-value="name of the event to trigger the form (if existing)"
 * data-action="click->table-row-checkbox-select#selectCheckbox">
 */

export default class extends Controller {
  static values = {
    event: String
  };
  eventValue!: string;

  selectCheckbox(event: Event) {
    if (!(event.target instanceof HTMLInputElement) && !(event.target as Element).classList.contains('form-check-label')) {
      const checkbox = this.element.querySelector('input[type=checkbox]') as HTMLInputElement;
      if (!checkbox.disabled) {
        checkbox.checked = !checkbox.checked;
        const form = checkbox.closest('form');
        if (form) {
          form.dispatchEvent(new Event(this.eventValue, { bubbles: true }));
        }
      }
    }
  }
}
