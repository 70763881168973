import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  async connect() {
    const TinyMDE = await import(/* webpackChunkName: "tiny-markdown-editor" */ 'tiny-markdown-editor');
    const tinyMDE = new TinyMDE.Editor({ textarea: this.element.id });
    new TinyMDE.CommandBar({
      element: document.getElementById('markdowncommand'),
      editor: tinyMDE,
      commands: ['bold', 'italic', '|', 'h1', 'h2', '|', 'ul', 'ol']
    });
  }
}
