import { Controller } from '@hotwired/stimulus';

/**
 * Add CSS-style for sticky elements, depending on the height of an element on top of the page.
 * You can also pass a value (in pixel) which will be added to the height of the top element.
 * If you do not need the position: sticky; style, you can pass 'false' to the value and set it manually.
 *
 * Usage:
 * <div data-controller="sticky-top"
 *  data-sticky-top-top-elements-selector-value="#k-sticky-top"
 *  data-sticky-top-additional-space-value="10"
 *  data-sticky-top-position-sticky-value="false">
 */

export default class extends Controller {
  static values = {
    topElementsSelector: String,
    additionalSpace: Number,
    positionSticky: Boolean
  };

  hasTopElementsSelectorValue!: boolean;
  topElementsSelectorValue!: string;
  hasAdditionalSpaceValue!: boolean;
  additionalSpaceValue!: number;
  hasPositionStickyValue!: boolean;
  positionStickyValue!: boolean;

  connect() {
    if (this.hasTopElementsSelectorValue) {
      const parentElement = <HTMLElement>document.querySelector(this.topElementsSelectorValue);
      if (parentElement) {
        const element = <HTMLElement>this.element;
        element.style.top = parentElement.offsetHeight + (this.hasAdditionalSpaceValue ? this.additionalSpaceValue : 0) + 'px';
        if (!this.hasPositionStickyValue || (this.hasPositionStickyValue && this.positionStickyValue)) {
          element.style.position = 'sticky';
        }
        element.style.zIndex = '2';
      }
    } else {
      console.error('[sticky top]: You need to pass the top elements selector to add CSS sticky styling.');
    }
  }
}
