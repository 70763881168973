import { Controller } from '@hotwired/stimulus';

/**
 * Sets a target input to another valueu.
 *
 * Usage:
 * <@form.switch ... attributes="data-controller='checkbox-disable' data-checkbox-disable-target-selector-value='#showDiscount, #showPrices'" />
 * <a data-controller="link-change" data-link-change-target-selector-value="#fanId" data-link-change-target-value-value="-1">Click me</button>
 */
export default class extends Controller {
  static values = {
    targetSelector: String,
    targetValue: String
  };
  targetSelectorValue!: string;
  targetValueValue!: string;

  connect() {
    this.element.addEventListener('click', this.inputChange());
  }

  disconnect() {
    this.element.removeEventListener('click', this.inputChange());
  }

  inputChange() {
    return (event: Event) => {
      const targetElems = document.querySelectorAll<HTMLInputElement>(this.targetSelectorValue);

      targetElems.forEach((targetElem) => {
        targetElem.value = this.targetValueValue;
        if (targetElem.form) {
          targetElem.form.dispatchEvent(new Event('live', { bubbles: true }));
        }
      });

      event.preventDefault();
    };
  }
}
