import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['fileInput', 'fileInputErrorMessage'];
  private fileInputTarget!: HTMLInputElement;
  private fileInputErrorMessageTarget!: HTMLDivElement;
  private readonly maxFileSizeInMb = 10;

  // is called on change event of input fields
  validate(_event: Event) {
    // fileInput
    if (this.fileExists(this.fileInputTarget) && !this.fileWithinAllowedSize(this.fileInputTarget)) {
      this.fileInputTarget.classList.add('is-invalid');
      this.fileInputErrorMessageTarget.hidden = false;
    } else {
      this.fileInputTarget.classList.remove('is-invalid');
      this.fileInputErrorMessageTarget.hidden = true;
    }
  }

  private fileExists(fileInput: HTMLInputElement) {
    return !!(fileInput.files && fileInput.files[0]);
  }

  private fileWithinAllowedSize(fileInput: HTMLInputElement) {
    const file = fileInput.files && fileInput.files[0];
    return file && file.size / 1024 / 1024 <= this.maxFileSizeInMb;
  }
}
