import { Controller } from '@hotwired/stimulus';

/**
 * Open the given link value and
 */
export default class extends Controller {
  connect() {
  }

  postAjax({ params: { url } }: { params: { url: string } }) {
    fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json;charset=utf-8' }
    });
  }

  postForm({ params: { url } }: { params: { url: string } }) {
    setTimeout(() => this.formSubmit(url), 150);
  }

  private formSubmit(url: string) {
    const form: HTMLFormElement = document.createElement('form');
    form.method = 'post';
    form.action = url;
    form.style.display = 'hidden';
    document.body.appendChild(form);
    form.submit();
  }
}
