// @ts-expect-error no type definitions exist
import { Autocomplete } from 'stimulus-autocomplete';

export default class extends Autocomplete {
  buildURL(query: string) {
    const company = document.getElementById('userFilterCompany') as HTMLInputElement;

    // @ts-expect-error urlValue exists on Autocomplete
    return `${new URL(this.urlValue, window.location.href).toString()}?searchString=${query}&company=${company.value}`;
  }
}
