import * as tablesort from 'tablesort';
import { Controller } from '@hotwired/stimulus';

/**
 * Allows a normal table to be sorted.
 * Library: https://github.com/tristen/tablesort
 *
 * Usage:
 * <table data-controller="table-sort">
 *  <th data-sort-method="number">
 *  <td data-sort="${model.attr?c}">
 *
 *  Use ?c in table rows to avoid formatting issues.
 *  Use braces before ?c if you use a default function (!value)
 *  e.g. ${(model.attr!1)?c}
 *
 *  If you have dynamic data that needs to sort the table again,
 *  you can use `data-table-sort-target="refresh"` on any element.
 */
export default class extends Controller {
  static targets = ['refresh'];

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  table: any;

  connect() {
    this.table = tablesort(this.element, {});
  }

  // Life cycle hook that is executed when the refresh target is inserted.
  refreshTargetConnected(_element: Element) {
    if (this.table) {
      this.table.refresh();
    }
  }
}

// ********************************
// Extensions for tablesort library
// ********************************

const cleanNumber = function (item: string) {
    return item.replace(/[^\-?0-9.]/g, '');
  },
  compareNumber = function (a: string, b: string) {
    let a1: number = parseFloat(a);
    let b1: number = parseFloat(b);

    a1 = isNaN(a1) ? 0 : a1;
    b1 = isNaN(b1) ? 0 : b1;

    return a1 - b1;
  };

tablesort.extend(
  'number',
  function (item: string) {
    return (
      item.match(/^[-+]?[£\x24Û¢´€]?\d+\s*([,.]\d{0,2})/) || // Prefixed currency
      item.match(/^[-+]?\d+\s*([,.]\d{0,2})?[£\x24Û¢´€]/) || // Suffixed currency
      item.match(/^[-+]?(\d)*-?([,.]){0,1}-?(\d)+([E,e][-+][\d]+)?%?$/)
    ); // Number
  },
  function (a: string, b: string) {
    a = cleanNumber(a);
    b = cleanNumber(b);

    return compareNumber(b, a);
  }
);

tablesort.extend(
  'eec',
  function (item: string) {
    return item.search(/([A-E]\+?)/i) !== -1;
  },
  function (a: string, b: string) {
    const eecTypes = ['A+', 'A', 'B', 'C', 'D', 'E'];
    return eecTypes.indexOf(b) - eecTypes.indexOf(a);
  }
);

tablesort.extend(
  'date',
  function (item: string) {
    return !isNaN(Date.parse(item));
  },
  function (a: string, b: string) {
    let d1 = Date.parse(a);
    let d2 = Date.parse(b);

    if (isNaN(d1)) {
      d1 = 0;
    }
    if (isNaN(d2)) {
      d2 = 0;
    }

    return d1 > d2 ? -1 : d1 === d2 ? 0 : 1;
  }
);

tablesort.extend(
  'nomenclature',
  function (item: string) {
    return item.search(/^[\w\-\s]+$/) !== -1;
  },
  function (a: string, b: string) {
    a = a.replace('-', '').replace(' ', '');
    b = b.replace('-', '').replace(' ', '');

    return b.localeCompare(a);
  }
);

tablesort.extend(
  'string',
  function (item: string) {
    return item !== '';
  },
  function (a: string, b: string) {
    a = a.replace(/\s/g, '');
    b = b.replace(/\s/g, '');

    return b.localeCompare(a);
  }
);
