import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    clickSourceId: String,
    clickTargetId: String
  };

  clickSourceIdValue!: string;
  clickTargetIdValue!: string;

  connect() {
    const sourceElement: HTMLInputElement | null = <HTMLInputElement>document.getElementById(this.clickSourceIdValue);
    const targetElement: HTMLInputElement | null = <HTMLInputElement>document.getElementById(this.clickTargetIdValue);
    sourceElement?.addEventListener('click', () => targetElement.click());
  }
}
