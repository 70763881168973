import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String
  };
  hasUrlValue!: boolean;
  urlValue!: string;

  load() {
    this.fetchContent();
  }

  fetchContent() {
    fetch(this.urlValue, {
      headers: new Headers({
        'x-content-loader-request': 'true'
      })
    })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;
      });
  }
}
