import { Controller } from '@hotwired/stimulus';
import Popover from 'bootstrap/js/dist/popover';

const myUpdatedAllowList = Object.assign({}, Popover.Default.allowList);
myUpdatedAllowList.dl = [];
myUpdatedAllowList.dt = [];
myUpdatedAllowList.dd = [];
myUpdatedAllowList.figure = [];
myUpdatedAllowList.figcaption = [];

export type Trigger = 'hover' | 'click' | 'focus' | 'manual' | 'click hover' | 'click focus' | 'hover focus' | 'click hover focus' | undefined;

/**
 * This is a generalized version of a bootstrap popover.
 *
 * Usage:
 * <span data-controller="popover" data-content="This is the content I want to show.">
 *
 * Usage with HTML element as content:
 * <span data-controller="popover" data-content-selector="#selector-with-popover-content" >
 *
 * Default behaviour is, that the popover shows up on mouseenter of the parent element and will be hidden on mouseleave.
 * If you want to add a link as content, and you have to enter the popover with the mouse, it is possible that the popover stays open.
 *
 * Usage:
 * <span data-controller="popover" data-content-selector="#selector-with-popover-content" data-popover-stay-open-value="true">
 */
export default class extends Controller {
  static values = {
    stayOpen: Boolean
  };
  popover!: Popover;

  stayOpenValue!: boolean;

  connect() {
    const content = (this.element as HTMLElement).dataset.contentSelector || (this.element as HTMLElement).dataset.content;
    this.popover = new Popover(this.element, {
      html: true,
      trigger: this.stayOpenValue ? 'manual' : ('hover' as Trigger),
      animation: false,
      // customClass: 'k-popover-product-list',
      allowList: myUpdatedAllowList,
      content: function () {
        if (content && content.startsWith('#')) {
          const contentElement = document.querySelector(content);
          return contentElement ? contentElement.innerHTML : 'Missing content';
        } else {
          return content || 'Missing content';
        }
      }
    });

    if (this.stayOpenValue) {
      this.element.addEventListener('mouseenter', () => {
        this.popover.toggle();
        setTimeout(() => {
          document.getElementsByClassName('popover')[0].addEventListener('mouseleave', () => {
            this.popover.hide();
          });
        }, 100);
      });
      this.element.addEventListener('mouseleave', () => {
        setTimeout(() => {
          if (!document.querySelectorAll('.popover:hover').length) {
            this.popover.hide();
          }
        }, 100);
      });
    }
  }

  disconnect() {
    this.popover.dispose();
  }
}
