import { Controller } from '@hotwired/stimulus';

/**
 * Sends a link as post request instead of get.
 * In the background a form is created and submitted.
 *
 * Usage:
 * <form action="...">
 *     <a href="/users/me/delete" data-controller="link-confirm" data-link-confirm-message-value="Are you sure?">Delete</a>
 * </form>
 */
export default class extends Controller {
  static values = {
    message: String
  };

  messageValue!: string;

  connect() {
    if (!this.messageValue) {
      throw 'Please add message for controller \'link-confirm\'. Example: data-link-confirm-message-value="Are you sure?"';
    }
    this.element.addEventListener('click', (event) => {
      const answer = confirm(this.messageValue);
      if (!answer) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    });
  }
}
