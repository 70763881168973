var Helpers = {};

Helpers.getWindowWidth = function () {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth;
  return x;
};

Helpers.getWindowHeight = function () {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    y = w.innerHeight || e.clientHeight || g.clientHeight;
  return y;
};

Helpers.findElement = function (element, condition) {
  var foundElement = false;

  function path(element) {
    if (element && !foundElement) {
      if (typeof condition == 'function') {
        foundElement = condition(element);
      } else if (typeof condition == 'string') {
        if (element.classList && element.classList.contains(condition)) {
          foundElement = element;
        }
      }
    }

    if (element.parentNode && !foundElement) {
      path(element.parentNode);
    }
  }

  path(element);

  return foundElement;
};

export default Helpers;
