import { Controller } from '@hotwired/stimulus';

/**
 * Sends a link as post request instead of get.
 * In the background a form is created and submitted.
 *
 * Usage:
 * <form action="...">
 *     <a href="/users/me/delete" data-controller="link-post">Delete</a>
 * </form>
 */
export default class extends Controller {
  connect() {
    this.element.addEventListener('click', (event) => {
      event.preventDefault();
      this.handleClick(<HTMLLinkElement>this.element);
    });
  }

  private handleClick(element: HTMLLinkElement) {
    const to = element.href;
    const csrf = this.buildHiddenInput('_csrf_token', 'csrf');
    const form = document.createElement('form');

    form.method = 'post';
    form.action = to;
    form.style.display = 'hidden';

    form.appendChild(csrf);
    document.body.appendChild(form);
    form.submit();
  }

  private buildHiddenInput(name: string, value: string) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    return input;
  }
}
