import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['activeButton', 'inactiveButton'];

  private activeButtonTarget!: HTMLButtonElement;
  private inactiveButtonTarget!: HTMLButtonElement;

  block(_event: Event) {
    this.inactiveButtonTarget.classList.remove('hiddenDiv');
    this.activeButtonTarget.classList.add('hiddenDiv');
  }

  // this is used via dispatch in form-live-controller
  unblock() {
    this.inactiveButtonTarget.classList.add('hiddenDiv');
    this.activeButtonTarget.classList.remove('hiddenDiv');
  }
}
