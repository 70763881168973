import {Controller} from "@hotwired/stimulus";
import morphdom from "morphdom";
import Modal from "bootstrap/js/dist/modal";

export default class extends Controller {
    static targets = ['container'];

    private containerTarget!: HTMLDivElement;
    private modal!: Modal;
    private modalElement!: Element|null;


    hide(){
        if (this.modalElement) {
            this.modalElement.innerHTML = '';
        }
        if (this.modal){
            this.modal.hide();
        }
        if (this.containerTarget){
            this.containerTarget.innerHTML = '<div></div>';
        }

    }

    async show(event: CustomEvent){
        const method = event.detail.dataMethod || 'GET';
        const templateUrl = event.detail.dataUrl;
        this.modalElement = this.containerTarget.firstElementChild;

        if (this.modalElement && templateUrl){
            const template = await fetch(templateUrl, { method: method }).then((response) => response.text());
            morphdom(this.modalElement, template);
            this.modal = new Modal(this.modalElement, { backdrop: 'static', keyboard: false});
            this.modal.show();
        }

    }
}