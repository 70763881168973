import { Controller } from '@hotwired/stimulus';
import { downloadFile } from '../download-file-controller';

const maximumSelectionCount = 10;

export default class extends Controller {
  static targets = ['capcalcButton', 'annualSimulationButton'];

  static values = {
    message: String,
    directTypeSelection: Boolean
  };
  messageValue!: string;
  directTypeSelectionValue!: boolean;
  capcalcButtonTarget!: HTMLButtonElement;
  annualSimulationButtonTarget!: HTMLButtonElement;
  hasCapcalcButtonTarget!: boolean;
  hasAnnualSimulationButtonTarget!: boolean;


  connect() {
    const downloadAllBtn = document.getElementById('download-all-excel-btn');
    const downloadBar = document.getElementById('download-bar');
    const downloadBtn = document.getElementById('download-excel-btn');
    const compareBtn = document.getElementById('compare-btn');
    const productSelectionText = document.getElementById('product-selection-text');

    downloadBtn?.classList.add('d-none');
    compareBtn?.classList.add('d-none');
    if (this.hasCapcalcButtonTarget) {
      this.capcalcButtonTarget?.classList.add('d-none');
    }
    if (this.hasAnnualSimulationButtonTarget) {
      this.annualSimulationButtonTarget?.classList.add('d-none');
    }

    productSelectionText?.classList.add('d-none');
    if (downloadAllBtn) {
      downloadBar?.classList.remove('d-none');
      downloadAllBtn.classList.remove('d-none');
    }

    document.addEventListener('click', (event) => {
      const target = <HTMLElement>event.target;
      const selectorList = [...document.querySelectorAll('[data-download-check]')];
      if (selectorList.includes(target)) {
        const checkboxElem = <HTMLInputElement>target;
        const checkboxChecked = checkboxElem.checked;
        const trElem = checkboxElem.closest('tr');

        const checkedCheckboxes = [...document.querySelectorAll('[data-download-check]:checked')];
        if (this.getCheckedIds().length > maximumSelectionCount) {
          event.preventDefault();
          return;
        }
        if (checkboxChecked) {
          trElem?.classList.add('table-active');
        } else {
          trElem?.classList.remove('table-active');
        }

        const downloadCountText = <HTMLSpanElement>document.getElementById('download-count');
        const capcalcBtn = document.getElementById('capcalc-btn');
        const locationSelected = downloadBar?.getAttribute('data-location-selected');
        if (locationSelected) {
          downloadBar?.classList.remove('d-none');
        }

        if (checkedCheckboxes.length > 0) {
          downloadBar?.classList.remove('d-none');
          downloadBtn?.classList.remove('d-none');
          compareBtn?.classList.remove('d-none');
          if (this.hasCapcalcButtonTarget) {
            if (checkedCheckboxes.length === 1 && checkedCheckboxes[0].getAttribute('data-capcalc-download') === 'true') {
              this.capcalcButtonTarget?.classList.remove('d-none');
            } else {
              this.capcalcButtonTarget?.classList.add('d-none');
            }
          }
          if (this.hasAnnualSimulationButtonTarget) {
            if (this.directTypeSelectionValue && checkedCheckboxes.length === 1) {
              this.annualSimulationButtonTarget?.classList.remove('d-none');
            } else if (!this.directTypeSelectionValue) {
              this.annualSimulationButtonTarget?.classList.remove('d-none');
            } else {
              this.annualSimulationButtonTarget?.classList.add('d-none');
            }
          }
          if (downloadCountText) {
            downloadCountText.innerHTML = String(checkedCheckboxes.length);
          }
          productSelectionText?.classList.remove('d-none');
        } else {
          downloadBtn?.classList.add('d-none');
          compareBtn?.classList.add('d-none');
          capcalcBtn?.classList.add('d-none');
          if (this.hasCapcalcButtonTarget) {
            this.capcalcButtonTarget?.classList.add('d-none');
          }
          if (this.hasAnnualSimulationButtonTarget) {
            this.annualSimulationButtonTarget?.classList.add('d-none');
          }
          productSelectionText?.classList.add('d-none');
          if (!downloadBtn && !locationSelected) {
            downloadBar?.classList.add('d-none');
          }
        }

        const downloadMaxReachedText = document.getElementById('download-max-reached');
        if (checkedCheckboxes.length >= maximumSelectionCount) {
          downloadMaxReachedText?.classList.remove('d-none');
        } else {
          downloadMaxReachedText?.classList.add('d-none');
        }
      }
    });
  }

  showCompareTable() {
    const form = <HTMLFormElement>document.getElementById('compareForm');

    // create hidden input with productIds
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'productIds[]');
    hiddenField.setAttribute('value', this.getCheckedIds().join(','));
    form.appendChild(hiddenField);

    form.submit();
  }

  downloadExcel({ params: { url } }: { params: { url: string } }) {
    const uri = url ? url : '/selector/excel_download';

    downloadFile({
      toastMessage: this.messageValue,
      url: uri,
      method: 'POST',
      body: JSON.stringify(this.getCheckedIds()),
      headers: { 'Content-Type': 'application/json; charset=utf-8' }
    }).catch((error) => {
      console.error('Error downloading file:', error);
    });
  }

  downloadAllExcel({ params: { url } }: { params: { url: string } }) {
    const uri = url ? url : '/selector/excel_download';

    downloadFile({
      toastMessage: this.messageValue,
      url: uri,
      method: 'POST',
      body: JSON.stringify(this.getAllProductIds()),
      headers: { 'Content-Type': 'application/json; charset=utf-8' }
    }).catch((error) => {
      console.error('Error downloading file:', error);
    });
  }

  downloadCapCalc() {
    downloadFile({
      toastMessage: this.messageValue,
      url: '/rt/download/capcalc/' + this.getCheckedIds()[0]
    }).catch((error) => {
      console.error('Error downloading file:', error);
    });
  }

  getCheckedIds(): string[] {
    const checkedCheckboxes = [...document.querySelectorAll('[data-download-check]:checked')];
    return checkedCheckboxes.map((checkbox) => checkbox.getAttribute('data-download-check') ?? '');
  }

  getAllProductIds(): string[] {
    const checkboxes = [...document.querySelectorAll('[data-download-check]')];
    return checkboxes.map((checkbox) => checkbox.getAttribute('data-download-check') ?? '');
  }
}
