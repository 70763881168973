import { Controller } from '@hotwired/stimulus';
import Modal from 'bootstrap/js/dist/modal';

export default class extends Controller {
  connect() {
    if (this.element) {
      const modal = this.element as HTMLElement;
      new Modal(modal).show();
    } else {
      alert('Modal not found!');
    }
  }
}
