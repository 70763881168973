import {Controller} from '@hotwired/stimulus';

/**
 * Allows to click on the given element and redirect to a given url.
 *
 * Usage:
 * data-controller="redirect"
 * data-redirect-url-value='the url to redirect to'
 * data-action="click->redirect#redirect"
 */
export default class extends Controller {

    static values = {
        url: String
    };
    urlValue!: String

    redirect() {
        if (this.urlValue) {
            location.href = this.urlValue.valueOf();
        }
    }
}
