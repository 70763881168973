import { Controller } from '@hotwired/stimulus';
import Toast from 'bootstrap/js/dist/toast';

/**
 * Allows to download a file with a warning that the download may take longer.
 */
export default class extends Controller {
  static values = {
    message: String,
    options: Object
  };

  messageValue!: string;
  optionsValue!: object;

  connect() {
    const message = this.messageValue;
    const toastStr = `
            <div class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="d-flex">
                <div class="toast-body">
                  ${message}
               </div>
                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>    
        `;

    this.element.innerHTML = toastStr;
    if (this.element.firstElementChild) {
      const toast = new Toast(this.element.firstElementChild, this.optionsValue);
      toast.show();

      this.element.addEventListener('hidden.bs.toast', () => {
        this.element.remove();
      });
    }
  }
}

export function addToast(message: string, options: object) {
  const toastDiv = <HTMLDivElement>document.querySelector('.toast-container');
  toastDiv.insertAdjacentHTML(
    'beforeend',
    '<div data-controller="toast" data-toast-options-value=\'' + JSON.stringify(options) + '\' data-toast-message-value="' + message + '"></div>'
  );
}

export function hideToast() {
  const toast = document.querySelector('[data-controller="toast"]') as Element;

  if (toast && toast.firstElementChild) {
    const instance = Toast.getInstance(toast.firstElementChild) as Toast;
    if (instance) {
      instance.hide();
    }
  }
}
