import { Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select';

export default class extends Controller {
  slimSelect!: SlimSelect;

  connect() {
    this.slimSelect = this.createSlimSelect();
  }

  disconnect() {
    if (this.slimSelect) {
      this.slimSelect.destroy();
    }
  }

  private createSlimSelect(): SlimSelect {
    return new SlimSelect({
      select: this.element,
      settings: {
        closeOnSelect: false
      }
    });
  }
}
