import { Controller } from '@hotwired/stimulus';
import { addToast } from './toast-controller';

export default class extends Controller {
  static targets = ['input', 'successMessage'];
  static values = { message: String };

  private inputTarget!: HTMLInputElement | HTMLTextAreaElement;
  private successMessageTarget!: HTMLDivElement;
  private hasSuccessMessageTarget!: boolean;

  messageValue!: string;
  hasMessageValue!: boolean;

  copyInput(event: Event) {
    event.preventDefault();
    const input = this.inputTarget;
    input.select();
    document.execCommand('copy');

    if (this.hasSuccessMessageTarget) {
      this.successMessageTarget.removeAttribute('hidden');
    } else if (this.hasMessageValue) {
      addToast(this.messageValue, {});
    }
  }

  copyText(event: Event) {
    event.preventDefault();
    const text = (<HTMLElement>event.currentTarget).dataset.clipboardText;

    if (text) {
      copyTextToClipboard(text);

      if (this.hasSuccessMessageTarget) {
        this.successMessageTarget.removeAttribute('hidden');
      } else if (this.hasMessageValue) {
        addToast(this.messageValue, {});
      }
    } else {
      alert('clipboard text is missing');
    }
  }
}

function copyTextToClipboard(text: string) {
  const textArea = <HTMLTextAreaElement>document.createElement('textarea');
  textArea.value = text;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
